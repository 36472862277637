import * as React from "react"

import Seo from "../components/seo"
import DesktopDesign from "../components/desktop"
import MobileDesign from "../components/mobile"

const IndexPage = () => {
  return (
    <>
      <Seo title="VLog Trans" />
      <MobileDesign />
      <DesktopDesign />
    </>
  )
}

export default IndexPage
