import React from "react"
import { Formik } from "formik"

import ContactNameIcon from "../../images/icon-contact-name.svg"
import ContactEmailIcon from "../../images/icon-contact-email.svg"
import ContactSubjectIcon from "../../images/icon-contact-subject.svg"
import ContactMessageIcon from "../../images/icon-contact-message.svg"
import ContactSendIcon from "../../images/icon-contact-send.svg"
import ContactSendingIcon from "../../images/icon-contact-sending.svg"
import ContactSentIcon from "../../images/icon-contact-sent.svg"

const axios = require("axios").default

export default function ContactFormComponent(props) {
  const [isSent, setIsSent] = React.useState(false)

  return (
    <Formik
      initialValues={{ subject: "", message: "", name: "", email: "" }}
      validate={values => {
        const errors = {}
        if (!values.email) {
          errors.email = "Required"
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address"
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        axios
          .post(
            "/api/contact.php",
            {
              ...values,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then(response => {
            setSubmitting(false)
            resetForm()
            setIsSent(true)
          })
          .catch(error => {
            setSubmitting(false)
            alert(error)
          })
        // setTimeout(() => {
        //   alert(JSON.stringify(values, null, 2))
        //   setSubmitting(false)
        //   setIsSent(true)
        // }, 400)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div className="w-8 m-3 absolute">
              <ContactNameIcon className="w-4 m-auto" />
            </div>
            <input
              type="text"
              name="name"
              // className="w-full bg-transparent outline-none px-3"
              className="w-full p-3 pl-12 rounded bg-gray-200 outline-none focus:ring-2 ring-black focus:bg-white hover:bg-white flex"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              placeholder="Name"
              required={true}
            />
            <span className="text-red text-sm">
              {errors.name && touched.name && errors.name}
            </span>
          </div>

          <div
            className="mt-2 lg:mt-6"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div className="w-8 m-3 absolute">
              <ContactEmailIcon className="w-5 m-auto" />
            </div>
            <input
              type="email"
              name="email"
              // className="w-full bg-transparent outline-none px-3"
              className="w-full p-3 pl-12 rounded bg-gray-200 outline-none focus:ring-2 ring-black focus:bg-white hover:bg-white flex"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email"
              required={true}
            />
            <span className="text-red-500 text-sm">
              {errors.email && touched.email && errors.email}
            </span>
          </div>

          <div
            className="mt-2 lg:mt-6"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div className="w-8 m-3 absolute">
              <ContactSubjectIcon className="w-4 m-auto" />
            </div>
            <input
              type="text"
              name="subject"
              // className="w-full bg-transparent outline-none px-3"
              className="w-full p-3 pl-12 rounded bg-gray-200 outline-none focus:ring-2 ring-black focus:bg-white hover:bg-white flex"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.subject}
              placeholder="Subject"
              required={true}
            />
            <span className="text-red-500 text-sm">
              {errors.subject && touched.subject && errors.subject}
            </span>
          </div>

          <div
            className="mt-2 lg:mt-6"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            <div className="w-8 m-3 absolute">
              <ContactMessageIcon className="w-5 m-auto" />
            </div>
            <textarea
              type="text"
              name="message"
              // className="w-full bg-transparent outline-none px-3"
              className="w-full p-3 pl-12 rounded bg-gray-200 outline-none focus:ring-2 ring-black focus:bg-white hover:bg-white flex"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              placeholder="Message"
              required={true}
              rows={7}
            />
            <span className="text-red-500 text-sm">
              {errors.message && touched.message && errors.message}
            </span>
          </div>

          <SubmitButton isSent={isSent} isSubmitting={isSubmitting} />
        </form>
      )}
    </Formik>
  )
}

const SubmitButton = props => {
  if (props.isSubmitting) {
    return (
      <button
        type="submit"
        className="w-full p-3 py-4 mt-8 bg-green-200 text-white rounded relative"
        disabled={true}
      >
        <span className="font-bold text-lg">Sending</span>
        <div className="absolute right-6 top-6">
          <ContactSendingIcon className="h-8" />
        </div>
      </button>
    )
  }

  if (props.isSent) {
    return (
      <button
        type="submit"
        className="w-full p-3 py-4 mt-8 bg-green-400 text-white rounded relative"
      >
        <span className="font-bold text-lg">Sent</span>
        <div className="absolute right-6 top-4">
          <ContactSentIcon className="h-7" />
        </div>
      </button>
    )
  }

  return (
    <button
      type="submit"
      className="w-full p-3 py-4 mt-8 bg-black hover:bg-gray-700 text-white rounded relative"
    >
      <span className="font-bold text-lg">Send</span>
      <div className="absolute right-6 top-3">
        <ContactSendIcon className="h-8" />
      </div>
    </button>
  )
}
