import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import GoogleMapReact from "google-map-react"
import scrollTo from "gatsby-plugin-smoothscroll"

import CargoIcon from "../images/icon-cargo.svg"
import OceanIcon from "../images/icon-ocean.svg"
import SpecializedtIcon from "../images/icon-specialized.svg"
import AirFreightIcon from "../images/icon-air-freight.svg"
import FreightIcon from "../images/icon-freight.svg"
import WarehouseIcon from "../images/icon-warehouse.svg"
import CustomIcon from "../images/icon-custom.svg"
import EmailIcon from "../images/icon-email.svg"
import PhoneIcon from "../images/icon-phone.svg"
import FaxIcon from "../images/icon-fax.svg"
import PinIcon from "../images/icon-pin.svg"
import ToTopIcon from "../images/icon-to-top.svg"

import ServiceBox from "../components/atoms/serviceBox"
import ServiceList from "../components/sections/serviceList"
import ContactForm from "../components/sections/contactForm"

export default function DesktopView(props) {
  return (
    <div className="hidden lg:block">
      <div className="fixed top-0 left-0 right-0 bg-gray-500 backdrop-filter backdrop-blur-sm bg-opacity-50 text-white z-50 block">
        <div className="container mx-auto p-3 flex justify-between">
          <div className="flex-1">
            <StaticImage
              src="../images/logo.png"
              formats={["auto", "webp", "avif"]}
              alt="VLog Trans Logo"
              className="w-6 h-6"
            />
            <a href="#" className="text-lg font-black ml-8 headline">
              VLOG TRANS
            </a>
          </div>
          <div className="space-x-6 mx-auto text-md leading-7 flex justify-center flex-1">
            <span
              className="cursor-pointer"
              onClick={() => scrollTo("#d-header")}
            >
              Home
            </span>
            <span
              className="cursor-pointer"
              onClick={() => scrollTo("#d-about")}
            >
              About
            </span>
            <span
              className="cursor-pointer"
              onClick={() => scrollTo("#d-services")}
            >
              Services
            </span>
            <span
              className="cursor-pointer"
              onClick={() => scrollTo("#d-contact")}
            >
              Contact
            </span>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
      <header
        id="d-header"
        className="flex justify-center items-center w-screen h-screen bg-gray-100"
      >
        <div className="container mx-auto">
          <div className="absolute inset-0 block w-full h-full">
            <StaticImage
              src="../images/bgimg-cover.png"
              formats={["auto", "webp", "avif"]}
              alt="VLog Trans Hero Image"
              className="absolute inset-0 w-full h-full "
            />
          </div>
          <div className="relative flex flex-col items-center justify-center z-40">
            <h1
              className="text-8xl font-black headline text-center text-white mx-4"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              Your Satisfaction
              <br /> Is Our Priority
            </h1>
            <ServiceList />
          </div>
        </div>
      </header>
      <section id="d-about" className="bg-gray-100 py-24 text-center">
        <span
          class="text-2xl font-black headline"
          data-sal="slide-up"
          data-sal-duration="2000"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          VLOG TRANS SDN BHD
        </span>
        <h2
          className="text-6xl font-black headline mt-8"
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          Reliable Logistics Service
        </h2>
        <div className="container mx-auto max-w-3xl font-light">
          <p
            className="text-2xl py-8 leading-10"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            Since opening our doors, we’ve been committed to providing service
            of the highest quality, paying particular attention to working
            efficiently while keeping the lines of communication with our
            clients clear and concise.
          </p>
          <p
            className="text-2xl py-8 leading-10"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Our mission at Vlog Trans is simple: to provide high-quality
            services in a timely manner. Our team caters to each project’s
            specific needs to ensure excellence. We hope you’ll find what you’re
            looking for. For more information or general inquiries, feel free to
            get in touch today.
          </p>
        </div>
      </section>
      {/* <div
    data-sal="fade"
    data-sal-duration="1000"
    data-sal-delay="300"
    data-sal-easing="ease"
  > */}
      <StaticImage
        src="../images/bgimg-cargo.png"
        formats={["auto", "webp", "avif"]}
        alt="VLog Trans"
        className=""
      />
      {/* </div> */}
      <section id="d-services" className="bg-gray-100 py-24 text-center">
        <span class="text-2xl font-black headline uppercase">Services</span>
        <h2 className="text-6xl font-black headline mt-8">What We Provide</h2>
        <div className="py-8">
          <ServiceList />
        </div>
        <div className="container mx-auto max-w-3xl grid grid-cols-2 gap-4 px-4">
          <ServiceBox
            icon={CargoIcon}
            label="Shipment"
            title="Cargo Insurance"
            desc="FCL / LCL"
          />
          <ServiceBox
            icon={OceanIcon}
            label="Export & Import Booking"
            title="Ocean Freight"
            desc="FCL / LCL"
          />
          <ServiceBox
            icon={WarehouseIcon}
            label="Bonded / Non Bonded (Johor)"
            title="Warehouse"
            desc="Transloading & Storage"
          />
          <ServiceBox
            icon={AirFreightIcon}
            label="Export & Import Booking"
            title="Air Freight"
            desc="FCL / LCL"
          />
          <ServiceBox
            icon={SpecializedtIcon}
            label="Specialized Products"
            title="Specialized Logistics"
            desc="Metal, Fresh Agriculture Product"
          />
          <ServiceBox
            icon={CustomIcon}
            label="Type K1 / K2 / K3 / K4 / K8 / K9 / ZB1 / ZB2"
            title="Custom Brokerage"
            desc="Johor Bahru* & Port Klang"
          />
        </div>
        <div className="space-x-4 pt-8 text-xs italic">
          <span className="font-semibold">Johor Bahru</span>
          <span>T.Puteri 1st link</span>
          <span>T. Kupang 2nd link</span>
          <span>Johor Port (Pasir Gudang)</span>
          <span>Port Of Tanjung Pelepas</span>
          <span>Senai Airport</span>
        </div>
        <div className="space-x-4 pt-2 pb-8 text-xs italic">
          <span className="font-semibold">Port Klang</span>
          <span>West Port</span>
          <span>North Port</span>
          <span>KLIA</span>
        </div>
      </section>
      <div id="d-contact" class="grid grid-cols-2">
        <section className="bg-blue-500 min-h-screen">
          <div className="p-12 max-w-2xl mx-auto">
            <div
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <h2 className="text-5xl text-white font-black headline">
                Get In Touch
              </h2>
              <p className="text-2xl text-white mt-2 mb-12">
                Don’t Hesitate to Email Us
              </p>
            </div>
            <ContactForm />
          </div>
        </section>
        <section className="bg-gray-800 text-white">
          <div className="p-12 max-w-2xl mx-auto">
            <h2
              className="text-5xl text-white font-black headline"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              Contact
            </h2>
            <p
              className="text-2xl font-black headline mt-20"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              VLOG TRANS SDN BHD
            </p>
            <p
              className="italic font-light mb-10"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              Working Hour: 9am -6pm Mon-Fri
            </p>

            <div
              class="flex mb-8"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <div class="flex-shrink-0 mr-3 py-1">
                <EmailIcon className="w-6" />
              </div>
              <div class="flex-1 leading-relaxed">
                <a href="mailto:management@vlogtransport.com">
                  management@vlogtransport.com
                </a>
              </div>
            </div>

            <div
              class="flex mb-8"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <div class="flex-shrink-0 mr-3 py-1">
                <PhoneIcon className="w-5" />
              </div>
              <div class="flex-1 leading-relaxed">
                <a href="tel:6075590116">+(60)7-559 0116</a>
                <br />
                <a href="tel:6075506771">+(60)7-550 6771</a>
              </div>
            </div>

            <div
              class="flex mb-8"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <div class="flex-shrink-0 mr-3 py-1">
                <FaxIcon className="w-5" />
              </div>
              <div class="pt-2 flex-1 leading-relaxed">
                <a href="fax:6075502113">+(60) 7-550 2113 (FAX)</a>
              </div>
            </div>

            <div
              class="flex mb-6"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-easing="ease"
            >
              <div class="flex-shrink-0 mr-3 py-1">
                <PinIcon className="w-5" />
              </div>
              <div class="pt-2 flex-1 leading-relaxed">
                <p>
                  125, Jalan Seri Impian 1, Taman Impian Emas, 81300 Skudai,
                  Johor, Malaysia.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container mx-auto">
        <div
          className="mx-8 my-12 bg-gray-300 rounded-4xl overflow-hidden text-center flex items-center justify-center"
          data-sal="zoom-in"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          <div className="h-96 w-full">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA4ot9lFQtujqh88xLOYEWRatVQNCTzjyw",
              }}
              defaultCenter={{
                lat: 1.5466768400131208,
                lng: 103.7059609,
              }}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals={true}
              // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
              <div lat={1.5466768400131208} lng={103.7059609} text="My Marker">
                <StaticImage
                  src="../images/logo.png"
                  formats={["auto", "webp", "avif"]}
                  alt="VLog Trans Logo"
                  className="w-8 h-8"
                />
              </div>
            </GoogleMapReact>
          </div>
        </div>
        <span className="m-8 text-4xl font-black headline">Find Us Here</span>
        <p className="py-10 text-center">©2021 by Vlog Trans</p>
      </div>
      <button
        className="fixed bottom-4 right-4"
        onClick={() => scrollTo("#d-header")}
      >
        <ToTopIcon className="w-12 h-12" />
      </button>
    </div>
  )
}
