import React from "react"

export default function ServiceBoxAtom(props) {
  return (
    <div
      className="p-8 border border-gray-300 rounded-4xl text-center"
      data-sal="zoom-in"
      data-sal-duration="1000"
    >
      <props.icon className="mx-auto my-8 h-24" />
      <span className="text-xs">{props.label}</span>
      <h3 className="text-lg py-2 font-bold uppercase">{props.title}</h3>
      <p className="text-xs">{props.desc}</p>
    </div>
  )
}
